import React from 'react'

import './App.css';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';
import Footer from './components/Footer';
import { FallbackLoading } from './components/const';
import PageNotFound from './components/PageNotFound';
import Blog from './components/Blog';
import useAnalytics from './components/Hooks/useAnalytics';







const Navbar=React.lazy(()=>import('./components/Navbar'))
const Home=React.lazy(()=>import('./components/Home'))
const About=React.lazy(()=>import('./components/About'))
const Verticals=React.lazy(()=>import('./components/Verticals'))
const Services=React.lazy(()=>import('./components/Services'))
const BusinessModel=React.lazy(()=>import('./components/BusinessModel'))
const Academy=React.lazy(()=>import('./components/Academy'))
const Enterprenuer=React.lazy(()=>import('./components/Enterprenuer'))
const Buyers=React.lazy(()=>import('./components/Buyers'))
const Esg=React.lazy(()=>import('./components/Esg'))
const Solutions=React.lazy(()=>import('./components/Solutions'))
const Agriculture=React.lazy(()=>import('./components/Agriculture'))
const ComingSoon=React.lazy(()=>import('./components/ComingSoon'))
const Connect=React.lazy(()=>import('./components/Connect'))
const SoilHealth=React.lazy(()=>import('./components/SoilHealth'))
const CarbonSequencing=React.lazy(()=>import('./components/CarbonSequencing'))
const PrecisionFarming=React.lazy(()=>import('./components/PrecisionFarming'))
const RegeneFarming=React.lazy(()=>import('./components/RegeneFarming'))
const LearningBlog=React.lazy(()=>import('./components/LearningBlog'))
const PotentialBlog=React.lazy(()=>import('./components/PotentialBlog'))
const ImpactBlog=React.lazy(()=>import('./components/ImpactBlog'))


const PrivacyPolicy=React.lazy(()=>import('./components/PrivacyPolicy'))















function App() {
  useAnalytics();

  const location=useLocation();
  const shouldShowNavbar = () => {
    const { pathname } = location;

    return ![ '/404'].includes(pathname);
  };
  return (
    <div className="App">
      {shouldShowNavbar() && <Navbar /> }

      <Routes>
           
      <Route path="/404" element={<PageNotFound/>} />
    <Route path="*" element={<Navigate to="/404" />} />  

      <Route path="/"  element={<React.Suspense fallback={<FallbackLoading/>}>
<Home  />
</React.Suspense> } />

<Route path="/about"  element={<React.Suspense fallback={<FallbackLoading/>}>
<About  />
</React.Suspense> } />
<Route path="/verticals"  element={<React.Suspense fallback={<FallbackLoading/>}>
<Verticals  />
</React.Suspense> } />
<Route path="/business"  element={<React.Suspense fallback={<FallbackLoading/>}>
<BusinessModel  />
</React.Suspense> } />
{/* <Route path="/solutions"  element={<React.Suspense fallback={<FallbackLoading/>}>
<Solutions  />
</React.Suspense> } /> */}
<Route path="/academy"  element={<React.Suspense fallback={<FallbackLoading/>}>
<Academy  />
</React.Suspense> } />
{/* <Route path="/entreprenuer"  element={<React.Suspense fallback={<FallbackLoading/>}>
<Enterprenuer  />
</React.Suspense> } /> */}
<Route path="/buyers"  element={<React.Suspense fallback={<FallbackLoading/>}>
<Buyers  />
</React.Suspense> } />
<Route path="/buyers"  element={<React.Suspense fallback={<FallbackLoading/>}>
<Buyers  />
</React.Suspense> } />
{/* <Route path="/service"  element={<React.Suspense fallback={<FallbackLoading/>}>
<Services  />
</React.Suspense> } /> */}
{/* <Route path="/esg"  element={<React.Suspense fallback={<FallbackLoading/>}>
<Esg  />
</React.Suspense> } /> */}
<Route path="/agriculture"  element={<React.Suspense fallback={<FallbackLoading/>}>
<Agriculture  />
</React.Suspense> } />
<Route path="/coming"  element={<React.Suspense fallback={<FallbackLoading/>}>
<ComingSoon  />
</React.Suspense> } />
<Route path="/connect"  element={<React.Suspense fallback={<FallbackLoading/>}>
<Connect  />
</React.Suspense> } />
{/* <Route path="/blog"  element={<React.Suspense fallback={<FallbackLoading/>}>
<Blog  />
</React.Suspense> } /> */}
<Route path="/soil-health"  element={<React.Suspense fallback={<FallbackLoading/>}>
<SoilHealth  />
</React.Suspense> } />
<Route path="/carbon-sequence"  element={<React.Suspense fallback={<FallbackLoading/>}>
<CarbonSequencing  />
</React.Suspense> } />
<Route path="/precision-farming"  element={<React.Suspense fallback={<FallbackLoading/>}>
<PrecisionFarming  />
</React.Suspense> } />
<Route path="/regenerative-farming"  element={<React.Suspense fallback={<FallbackLoading/>}>
<RegeneFarming  />
</React.Suspense> } />
<Route path="/learning"  element={<React.Suspense fallback={<FallbackLoading/>}>
<LearningBlog  />
</React.Suspense> } />
<Route path="/potential"  element={<React.Suspense fallback={<FallbackLoading/>}>
<PotentialBlog  />
</React.Suspense> } />
<Route path="/impact"  element={<React.Suspense fallback={<FallbackLoading/>}>
<ImpactBlog  />
</React.Suspense> } />
<Route path="/privacy"  element={<React.Suspense fallback={<FallbackLoading/>}>
<PrivacyPolicy  />
</React.Suspense> } />

</Routes>


{shouldShowNavbar() &&<Footer/>}



      
    </div>
  );
}

export default App;
