export const FallbackLoading=()=>{
    return( <div className='container'>
    <div className='row align-items-center component-loader'  >
    <div className='col-xl-6 mx-auto text-center d-flex  justify-content-center'>
    
    <div className="fallbak-loader ">  </div>

   
  </div>

    </div>

  </div>
      )
    
  }