export const rightArrow= <svg xmlns="http://www.w3.org/2000/svg"  height="60" viewBox="0 0 108.305 83.971">
<g id="Group_35" data-name="Group 35" transform="translate(-9779.576 -644.122)">
  <path id="Path_233" data-name="Path 233" d="M11,57.153,36.576,31.576,11,6" transform="translate(9816.335 642.364)" fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="6"/>
  <path id="Path_234" data-name="Path 234" d="M16,57.153,41.576,31.576,16,6" transform="translate(9843.305 642.364)" fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="6"/>
  <path id="Path_235" data-name="Path 235" d="M74.335,10H29.576a25.576,25.576,0,1,0,0,51.153H35.97" transform="translate(9778.576 663.941)" fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="6"/>
</g>
</svg>

export const slideLeftArrow=<svg xmlns="http://www.w3.org/2000/svg"  height="50" viewBox="0 0 115.308 115.308">
<g id="square-rounded-arrow-right" transform="translate(115.308 115.308) rotate(180)">
  <path id="Path_223" data-name="Path 223" d="M0,0H115.308V115.308H0Z" fill="none"/>
  <path id="Path_224" data-name="Path 224" d="M12,46.436,31.218,27.218,12,8" transform="translate(45.654 30.436)" fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"/>
  <path id="Path_225" data-name="Path 225" d="M8,12H46.436" transform="translate(30.436 45.654)" fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"/>
  <path id="Path_226" data-name="Path 226" d="M46.24,3c34.592,0,43.24,8.648,43.24,43.24s-8.648,43.24-43.24,43.24S3,80.833,3,46.24,11.648,3,46.24,3Z" transform="translate(11.413 11.413)" fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"/>
</g>
</svg>  
export const slideRightArrow= <svg xmlns="http://www.w3.org/2000/svg" id="square-rounded-arrow-right"  height="50" viewBox="0 0 115.308 115.308">
<path id="Path_223" data-name="Path 223" d="M0,0H115.308V115.308H0Z" fill="none"/>
<path id="Path_224" data-name="Path 224" d="M12,46.436,31.218,27.218,12,8" transform="translate(45.654 30.436)" fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"/>
<path id="Path_225" data-name="Path 225" d="M8,12H46.436" transform="translate(30.436 45.654)" fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"/>
<path id="Path_226" data-name="Path 226" d="M46.24,3c34.592,0,43.24,8.648,43.24,43.24s-8.648,43.24-43.24,43.24S3,80.833,3,46.24,11.648,3,46.24,3Z" transform="translate(11.413 11.413)" fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"/>
</svg>

export const droneIcon=<svg xmlns="http://www.w3.org/2000/svg" id="drone"  height="96" viewBox="0 0 96.622 96.622">
<path id="Path_188" data-name="Path 188" d="M0,0H96.622V96.622H0Z" fill="none"/>
<path id="Path_189" data-name="Path 189" d="M10,10H26.1V26.1H10Z" transform="translate(30.259 30.259)" fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="3"/>
<path id="Path_190" data-name="Path 190" d="M20.591,20.591,6.5,6.5" transform="translate(19.668 19.668)" fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="3"/>
<path id="Path_191" data-name="Path 191" d="M31.035,15.092A14.091,14.091,0,1,0,15.092,31.035" transform="translate(9.063 9.063)" fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="3"/>
<path id="Path_192" data-name="Path 192" d="M14,20.591,28.091,6.5" transform="translate(42.363 19.668)" fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="3"/>
<path id="Path_193" data-name="Path 193" d="M29.983,31.035A14.091,14.091,0,1,0,14.04,15.092" transform="translate(42.484 9.063)" fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="3"/>
<path id="Path_194" data-name="Path 194" d="M14,14,28.091,28.091" transform="translate(42.363 42.363)" fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="3"/>
<path id="Path_195" data-name="Path 195" d="M14.04,29.983A14.091,14.091,0,1,0,29.983,14.04" transform="translate(42.484 42.484)" fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="3"/>
<path id="Path_196" data-name="Path 196" d="M20.591,14,6.5,28.091" transform="translate(19.668 42.363)" fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="3"/>
<path id="Path_197" data-name="Path 197" d="M15.092,14.04A14.091,14.091,0,1,0,31.035,29.983" transform="translate(9.063 42.484)" fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="3"/>
</svg>
export const padIcon=  <svg xmlns="http://www.w3.org/2000/svg" id="clipboard-data"  height="96" viewBox="0 0 96.622 96.622">
<path id="Path_198" data-name="Path 198" d="M0,0H96.622V96.622H0Z" fill="none"/>
<path id="Path_199" data-name="Path 199" d="M21.1,5H13.052A8.052,8.052,0,0,0,5,13.052V61.363a8.052,8.052,0,0,0,8.052,8.052H53.311a8.052,8.052,0,0,0,8.052-8.052V13.052A8.052,8.052,0,0,0,53.311,5H45.259" transform="translate(15.13 15.13)" fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="3"/>
<path id="Path_200" data-name="Path 200" d="M9,3m0,8.052A8.052,8.052,0,0,1,17.052,3H25.1a8.052,8.052,0,0,1,8.052,8.052h0A8.052,8.052,0,0,1,25.1,19.1H17.052A8.052,8.052,0,0,1,9,11.052Z" transform="translate(27.233 9.078)" fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="3"/>
<path id="Path_201" data-name="Path 201" d="M9,29.1V13" transform="translate(27.233 39.337)" fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="3"/>
<path id="Path_202" data-name="Path 202" d="M12,20.026V16" transform="translate(36.311 48.415)" fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="3"/>
<path id="Path_203" data-name="Path 203" d="M15,23.052V15" transform="translate(45.389 45.389)" fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="3"/>
<path id="Path_204" data-name="Path 204" d="M12,20.026V16" transform="translate(36.311 48.415)" fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="3"/>
</svg>
export const settingsIcon=<svg xmlns="http://www.w3.org/2000/svg" id="settings-star"  height="30" viewBox="0 0 96.622 96.622">
<path id="Path_205" data-name="Path 205" d="M0,0H96.622V96.622H0Z" transform="translate(0)" fill="none"/>
<path id="Path_206" data-name="Path 206" d="M32.49,70.164a6.937,6.937,0,0,0-10.359-4.292,6.944,6.944,0,0,1-9.541-9.541A6.941,6.941,0,0,0,8.3,45.977a6.939,6.939,0,0,1,0-13.487,6.941,6.941,0,0,0,4.292-10.359,6.944,6.944,0,0,1,9.541-9.541A6.935,6.935,0,0,0,32.49,8.3a6.939,6.939,0,0,1,13.487,0,6.941,6.941,0,0,0,10.359,4.292,6.944,6.944,0,0,1,9.541,9.541A6.941,6.941,0,0,0,70.164,32.49a6.723,6.723,0,0,1,4.453,3.346" transform="translate(9.078 9.078)" fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="3"/>
<path id="Path_207" data-name="Path 207" d="M32.721,17.844A12.082,12.082,0,1,0,14.777,31.387" transform="translate(27.225 27.227)" fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="3"/>
<path id="Path_208" data-name="Path 208" d="M30.511,41.446l-8.744,4.581a1.578,1.578,0,0,1-2.287-1.651l1.671-9.706L14.077,27.8a1.566,1.566,0,0,1,.874-2.677L24.726,23.7,29.1,14.875a1.578,1.578,0,0,1,2.826,0L36.3,23.7l9.775,1.417a1.57,1.57,0,0,1,.874,2.677L39.871,34.67l1.667,9.7a1.57,1.57,0,0,1-2.283,1.655Z" transform="translate(41.151 42.361)" fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="3"/>
</svg>
export const bulbIcon=  <svg xmlns="http://www.w3.org/2000/svg" id="bulb"  height="96" viewBox="0 0 96.622 96.622">
<path id="Path_209" data-name="Path 209" d="M0,0H96.622V96.622H0Z" transform="translate(0)" fill="none"/>
<path id="Path_210" data-name="Path 210" d="M3,39.233H7.026M39.233,3V7.026M71.441,39.233h4.026m-62-25.766,2.818,2.818M65,13.467l-2.818,2.818" transform="translate(9.078 9.078)" fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="3"/>
<path id="Path_211" data-name="Path 211" d="M15.053,43.233a20.13,20.13,0,1,1,24.156,0,14.091,14.091,0,0,0-4.026,12.078,8.052,8.052,0,0,1-16.1,0,14.091,14.091,0,0,0-4.026-12.078" transform="translate(21.18 21.181)" fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="3"/>
<path id="Path_212" data-name="Path 212" d="M9.7,17H28.219" transform="translate(29.351 51.441)" fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="3"/>
</svg>
export const rightCornerarrowIcon=  <svg xmlns="http://www.w3.org/2000/svg"  height="96" viewBox="0 0 96.622 96.622">
<g id="arrow-up-left" transform="translate(96.622) rotate(90)">
  <path id="Path_213" data-name="Path 213" d="M0,0H96.622V96.622H0Z" fill="none"/>
  <path id="Path_214" data-name="Path 214" d="M0,0,40.259,40.259" transform="translate(28.181 28.181)" fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="3"/>
  <path id="Path_215" data-name="Path 215" d="M36.233,0H0V36.233" transform="translate(28.181 28.181)" fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="3"/>
</g>
</svg>


export const writetousIcon=<svg xmlns="http://www.w3.org/2000/svg"  height="10" viewBox="0 0 37.367 21.657">
<g id="Group_64" data-name="Group 64" transform="translate(1 1)">
  <path id="Path_301" data-name="Path 301" d="M3,7H8.9" transform="translate(-3 -7)" fill="none" stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"/>
  <path id="Path_302" data-name="Path 302" d="M3,11H6.931" transform="translate(-3 -3.137)" fill="none" stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"/>
  <path id="Path_303" data-name="Path 303" d="M9.609,10.54,8.43,22.335a3.931,3.931,0,0,0,3.912,4.323H28.028a3.931,3.931,0,0,0,3.912-3.54l1.179-11.795A3.931,3.931,0,0,0,29.207,7H13.521a3.931,3.931,0,0,0-3.912,3.54Z" transform="translate(2.225 -7)" fill="none" stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"/>
  <path id="Path_304" data-name="Path 304" d="M9.8,7.5l5.862,6.448a5.9,5.9,0,0,0,8.331.4L30.44,8.483" transform="translate(3.567 -6.517)" fill="none" stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"/>
</g>
</svg>
export const mailIcon=<svg xmlns="http://www.w3.org/2000/svg"  height="15" viewBox="0 0 29.225 29.224">
<g id="Group_63" data-name="Group 63" transform="translate(1 1)">
  <path id="Path_306" data-name="Path 306" d="M13.587,26.125V16.294A5.294,5.294,0,0,0,3,16.294v9.831H30.225V17.05A6.05,6.05,0,0,0,24.175,11H8.294" transform="translate(-3 1.1)" fill="none" stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"/>
  <path id="Path_307" data-name="Path 307" d="M12,15.1V3h6.05l3.025,3.025L18.05,9.05H12" transform="translate(1.612 -3)" fill="none" stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"/>
  <path id="Path_308" data-name="Path 308" d="M6,15H7.512" transform="translate(-1.463 3.15)" fill="none" stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"/>
</g>
</svg>
export const linkedinIcon=<svg xmlns="http://www.w3.org/2000/svg"  height="16" viewBox="0 0 33.452 33.452">
<g id="Group_62" data-name="Group 62" transform="translate(1 1)">
  <path id="Path_292" data-name="Path 292" d="M4,4,4,7.931A3.931,3.931,0,0,1,7.931,4H31.52a3.931,3.931,0,0,1,3.931,3.931V31.52a3.931,3.931,0,0,1-3.931,3.931H7.931A3.931,3.931,0,0,1,4,31.52Z" transform="translate(-4 -4)" fill="none" stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"/>
  <path id="Path_293" data-name="Path 293" d="M8,11v9.829" transform="translate(-0.137 2.76)" fill="none" stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"/>
  <path id="Path_294" data-name="Path 294" d="M8,8v.02" transform="translate(-0.137 -0.137)" fill="none" stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"/>
  <path id="Path_295" data-name="Path 295" d="M12,20.829V11" transform="translate(3.726 2.76)" fill="none" stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"/>
  <path id="Path_296" data-name="Path 296" d="M19.863,20.829v-5.9a3.931,3.931,0,0,0-7.863,0" transform="translate(3.726 2.76)" fill="none" stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"/>
</g>
</svg>
export const twitterIcon=<svg xmlns="http://www.w3.org/2000/svg"  height="16" viewBox="0 0 33.452 33.452">
<g id="Group_61" data-name="Group 61" transform="translate(1 1)">
  <path id="Path_297" data-name="Path 297" d="M4,4,4,7.931A3.931,3.931,0,0,1,7.931,4H31.52a3.931,3.931,0,0,1,3.931,3.931V31.52a3.931,3.931,0,0,1-3.931,3.931H7.931A3.931,3.931,0,0,1,4,31.52Z" transform="translate(-4 -4)" fill="none" stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"/>
  <path id="Path_3" data-name="Path 3" d="M1006.138,38.364c-.135.155-.263.3-.389.446l-1.719,2-1.942,2.26q-.79.92-1.582,1.839a.108.108,0,0,1-.074.032q-.591,0-1.183,0c-.064,0-.129-.008-.215-.014l6.461-7.514L999.033,28c.033,0,.057,0,.081,0h4.779a.116.116,0,0,1,.11.056q2.079,3.031,4.161,6.06c.019.028.04.054.066.09.039-.038.077-.069.109-.1q.593-.684,1.184-1.37l2.04-2.372q.973-1.132,1.945-2.267a.23.23,0,0,1,.2-.094c.41.006.82,0,1.23.006.026,0,.052,0,.095.008-.076.09-.142.168-.208.246q-1.509,1.755-3.017,3.51-1.408,1.64-2.814,3.283c-.116.135-.111.089-.012.235q.687,1.018,1.382,2.031,2.51,3.66,5.022,7.319c.063.093.125.186.194.288-.027,0-.046.006-.064.006-.4,0-.807.013-1.21.013-1.178,0-2.357,0-3.535,0a.172.172,0,0,1-.162-.087q-2.144-3.122-4.292-6.242c-.055-.08-.111-.158-.175-.249m7.451,5.509-.055-.082-6.025-8.622q-2.1-3-4.194-6.005a.14.14,0,0,0-.131-.068c-.61,0-1.221,0-1.831,0h-.334c.022.034.037.058.053.081q3.1,4.432,6.192,8.865,2.009,2.874,4.019,5.747a.173.173,0,0,0,.16.085c.512,0,1.023,0,1.534,0h.614" transform="translate(-991.17 -20.741)" fill="#fff" stroke="#fff" strokeWidth="1"/>
</g>
</svg>

export const callIcon=<svg xmlns="http://www.w3.org/2000/svg" width="95.095" height="95.458" viewBox="0 0 95.095 95.458">
<g id="Continuous_Support" data-name="Continuous Support" transform="translate(-438.453 -1715.271)">
  <path id="Path_310" data-name="Path 310" d="M70.667,33.121A33.75,33.75,0,0,0,6.083,24.787M4,8.121V24.787H20.667" transform="translate(435.042 1776.688) rotate(-60)" fill="none" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="3"/>
  <path id="Path_311" data-name="Path 311" d="M4,13a33.75,33.75,0,0,0,64.583,8.333M70.667,38V21.333H54" transform="translate(459.684 1790.915) rotate(-60)" fill="none" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="3"/>
  <path id="Path_313" data-name="Path 313" d="M5.754,4h5.508l2.754,6.885L10.573,12.95a15.146,15.146,0,0,0,6.885,6.885l2.065-3.442,6.885,2.754v5.508a2.754,2.754,0,0,1-2.754,2.754A22.031,22.031,0,0,1,3,6.754,2.754,2.754,0,0,1,5.754,4" transform="translate(470.607 1747.984)" fill="none" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="3"/>
</g>
</svg>
export const userIcon=<svg xmlns="http://www.w3.org/2000/svg" width="35"  viewBox="0 0 54 72">
<g id="Expert" transform="translate(-734 -1727)">
  <g id="Ellipse_2" data-name="Ellipse 2" transform="translate(745 1727)" fill="none" stroke="#000" stroke-width="3">
    <circle cx="17" cy="17" r="17" stroke="none"/>
    <circle cx="17" cy="17" r="15.5" fill="none"/>
  </g>
  <g id="Rectangle_275" data-name="Rectangle 275" transform="translate(734 1759)" fill="none" stroke="#000" stroke-width="3">
    <path d="M27,0h0A27,27,0,0,1,54,27V40a0,0,0,0,1,0,0H0a0,0,0,0,1,0,0V27A27,27,0,0,1,27,0Z" stroke="none"/>
    <path d="M27,1.5h0A25.5,25.5,0,0,1,52.5,27V38.5a0,0,0,0,1,0,0H1.5a0,0,0,0,1,0,0V27A25.5,25.5,0,0,1,27,1.5Z" fill="none"/>
  </g>
  <path id="Path_328" data-name="Path 328" d="M15,25.923l8.923,8.923L41.769,17" transform="translate(732.615 1756.885)" fill="none" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="3"/>
</g>
</svg>

export const heartIcon=<svg xmlns="http://www.w3.org/2000/svg" width="50"  viewBox="0 0 84.076 75.064">
<g id="Hands-on" transform="translate(-447.924 -1463.436)">
  <path id="Path_321" data-name="Path 321" d="M77.3,42.614,43.546,76.04,9.8,42.614a22.5,22.5,0,1,1,33.75-29.547A22.5,22.5,0,1,1,77.3,42.641" transform="translate(446.454 1460.96)" fill="none" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="3"/>
  <path id="Path_322" data-name="Path 322" d="M24.55,6,9.732,20.819a4.5,4.5,0,0,0,0,6.363l2.444,2.444a7.958,7.958,0,0,0,11.25,0l4.5-4.5a14.319,14.319,0,0,1,20.25,0L58.3,35.25" transform="translate(465.45 1468)" fill="none" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="3"/>
  <path id="Path_323" data-name="Path 323" d="M12.5,15.5l9,9" transform="translate(479.75 1501.25)" fill="none" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="3"/>
  <path id="Path_324" data-name="Path 324" d="M15,13l9,9" transform="translate(488.5 1492.5)" fill="none" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="3"/>
</g>
</svg>

export const languageIcon=<svg xmlns="http://www.w3.org/2000/svg" width="50" viewBox="0 0 74.877 74.878">
<g id="Regional_Language" data-name="Regional Language" transform="translate(-724.801 -1728.623)">
  <path id="Path_315" data-name="Path 315" d="M4,5H35.236" transform="translate(722.301 1729.585)" fill="none" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="3"/>
  <path id="Path_316" data-name="Path 316" d="M7,4C7,25.625,7,35.236,9.231,39.7" transform="translate(732.688 1726.123)" fill="none" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="3"/>
  <path id="Path_317" data-name="Path 317" d="M30.774,10.231c0,10.2-8.925,20.081-15.618,20.081S4,25.247,4,21.387Q4,8,17.387,8C26.312,8,39.7,10.544,39.7,20.749q0,10.2-8.925,14.025" transform="translate(722.301 1739.972)" fill="none" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="3"/>
  <path id="Path_318" data-name="Path 318" d="M12,51.161,29.849,11,47.7,51.161" transform="translate(749.999 1750.359)" fill="none" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="3"/>
  <path id="Path_319" data-name="Path 319" d="M40.567,18H12.9" transform="translate(753.115 1774.596)" fill="none" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="3"/>
</g>
</svg>