import React from 'react'
import { useNavigate } from 'react-router-dom'

const PageNotFound = () => {
  const navigate =useNavigate()
  return (
    <div className='container'>
      <div className='row align-items-center ' style={{height:'100vh'}} >
      <div className='col-xl-6 mx-auto text-center '>
      

      <h5>Page Not Found</h5>
      <button className='btn rounded-pill Bg-primary px-5 my-4 Text-white Fs-primary border-0' onClick={()=>navigate(-1)}>
         Back
      </button>
    </div>

      </div>

    </div>
  )
}

export default PageNotFound