import React from 'react'
import { linkedinIcon, mailIcon, twitterIcon, writetousIcon } from './Icons'
import { NavLink } from 'react-router-dom'

const Footer = () => {
  return (
    <div className="container-fluid Bg-dark">
        <div className="row">
          <div className='col-xl-10 mx-auto '>
            <div className='row '>
            <div className="col-xl-4  py-4 col-lg-4 col-12 text-start d-flex align-items-center">
          <div>
          <img src="/Assets/Images/Logowhite.svg" alt="logo" className="text-start img-fluid mb-2" />
           <p className='Text-white mt-2 text-center'> <span>&copy;</span> All Rights Reserved. 2024. <NavLink className='Text-white text-decoration-none' to={'/privacy'}>Privacy Policy</NavLink></p>
          </div>
          </div>
          <div className="col-xl-8 py-4 col-lg-8 col-12">
  
            <div className="row">
              <div className="col-xl-4 col-lg-4 col-5 col-md-4">
                <p className='Text-white lh-lg '><strong>Headquarters:</strong> <br/> #19, Lavelle Road, D' Souza Layout, Ashok Nagar, Bengaluru, Karnataka 560001</p>
                {/* <ul className="nav flex-column">
                  <li className="nav-item">
                    <NavLink className="nav-link  Text-white" to={'/'}>Home</NavLink>
                  </li>
                  <li className="nav-item">
                    <NavLink className="nav-link Text-white" to={'/about'}>About Us</NavLink>
                  </li>
                  <li className="nav-item">
                    <NavLink className="nav-link Text-white" to={'/'}>Company</NavLink>
                  </li>
                  <li className="nav-item">
                    <NavLink className="nav-link Text-white" to={'/verticals'}>Verticals</NavLink>
                  </li>
                  <li className="nav-item">
                    <NavLink className="nav-link Text-white" to={'/'}>Vel Network</NavLink>
                  </li>
                </ul> */}
              </div>
              {/* <div className="col-xl-4 col-lg-4 col-md-4  col-6">
                <ul className="nav flex-column">
                  <li className="nav-item">
                    <NavLink className="nav-link  Text-white" to={'/'}>Software</NavLink>
                  </li>
                  <li className="nav-item">
                    <NavLink className="nav-link Text-white" to={'/academy'}>Academy</NavLink>
                  </li>
                  <li className="nav-item">
                    <NavLink className="nav-link Text-white" to={'/entreprenuer'}>Micro-Entreprenuer</NavLink>
                  </li>
                  <li className="nav-item">
                    <NavLink className="nav-link Text-white" to={'/buyers'}>Industrial Buyers</NavLink>
                  </li>
                  <li className="nav-item">
                    <NavLink className="nav-link Text-white" to={'/service'}>Service</NavLink>
                  </li>
                </ul>
              </div> */}
              <div className="col-xl-4 col-lg-4 col-md-4 col-7">
                <ul className="nav flex-column">
                  <li className="nav-item">
                   
                  </li>
                  <li className="nav-item">
                    <a className="nav-link pe-0 Text-white" href="https://x.com/velaerodx" target='_blank'> <span className='me-2'>{twitterIcon}</span>Twitter/X</a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link pe-0 Text-white" href="https://www.linkedin.com/company/velaerodx" target='_blank'> <span className='me-2'>{linkedinIcon}</span>LinkedIn</a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link Text-white pe-0" href="mailto:contact@velaerodx.com" > <span className='me-1'>{mailIcon}</span>contact@velaerodx.com</a>
                  </li>
                  {/* <li className="nav-item mt-4 d-block d-md-none">
                  <NavLink className="btn rounded-pill Bg-primary px-3 Text-white Fs-primary border-0" to={'/connect'}> <span className='me-2'>{writetousIcon}</span>Write to us</NavLink>
                  </li> */}
                  
                </ul>
              </div>
              <div className="col-xl-4 col-lg-4 col-md-4 col-6 ">
              <NavLink className="btn rounded-pill Bg-primary px-3 Text-white Fs-primary border-0  " to={'/connect'}> <span className='me-2'>{writetousIcon}</span>Write to us</NavLink>
               
              </div>
            </div>
          </div>

            </div>

          </div>
        </div>
      </div>
  )
}

export default Footer