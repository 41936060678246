// src/hooks/useAnalytics.js
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const useAnalytics = () => {
  const location = useLocation();

  useEffect(() => {
    if (!window.gtag) return;
    window.gtag('config', 'G-0MJ6D57RJ', {
      page_path: location.pathname,
    });
  }, [location]);
};

export default useAnalytics;
